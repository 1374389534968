
@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  src: url(~fonts/ibmplexsans/woff2/IBMPlexSans-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 400;
  src: url(~fonts/ibmplexsans/woff2/IBMPlexSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 500;
  src: url(~fonts/ibmplexsans/woff2/IBMPlexSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 600;
  src: url(~fonts/ibmplexsans/woff2/IBMPlexSans-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-weight: 700;
  src: url(~fonts/ibmplexsans/woff2/IBMPlexSans-Bold.woff2) format('woff2');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes rotate{
  from{ transform: rotate(360deg); }
  to{ transform: rotate(-360deg); }
}

.yarl__thumbnails_vignette {
  background: none !important;
}

.yarl__thumbnails_thumbnail {
  border-color: rgba(0,0,0,0) !important;
}

.yarl__thumbnails_thumbnail_active {
  border-color: rgba(255,255,255,0.75) !important;
}


@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(155, 155, 155, 0.45);
  }
}